
import { initGSAP } from './gsapanims';
import { initFancybox } from './fancybox';
import { initPage } from './base';
import { initSliders } from './sliders';

class Fade extends highway.Transition {

    in({from, to, done}) {

        // Reset Scroll
        window.scrollTo(0, 0);
  
        // Remove Old View
        from.remove();

        document.body.className = to.dataset.bodyClass
  
        // Animation
        gsap.fromTo(to, 0.5,
            { 
                opacity     : 0 
            },
            {
                opacity     : 1,
                onComplete  : () => {

                    window.initThreeBG();

                    initFancybox();
                    
                    initSliders();

                    initPage();

                    initGSAP();

                    done();

                }
            }
        );

    }
  
    out({from, done}) {

        // Animation
        gsap.fromTo(from, 0.5, 
            {
                opacity     : 1 
            },
            {
                opacity     : 0,
                onComplete  : done
            }
        );

    }

}

new highway.Core({
    transitions : {
        default : Fade
    }
});