
//these are enqueued from a cdn in functions, except for split text which is part of club greensock
// import { gsap } from "./vendor/gsap";
// import { ScrollTrigger } from "./vendor/gsap/ScrollTrigger";
import { SplitText } from "./vendor/gsap/SplitText";

gsap.registerPlugin(ScrollTrigger, SplitText);

let headings, fadesTop, fadesBottom, fadesLeft, fadesRight, fadeIn;

function setupSplits() {

  headings.forEach(heading => {
    // Reset if needed
    if (heading.anim) {
      heading.anim.progress(1).kill();
      heading.split.revert();
    }

    const split = new SplitText(heading, {
      type: "lines,words,chars",
      linesClass: "split-line"
    });
    // Set up the anim
    gsap.from(split.lines, {
      scrollTrigger: {
        toggleActions: "restart pause resume reverse",
        trigger: heading,
        start: 'top 100%',
      },
      duration: 1,
      yPercent: 100,
      ease: 'power2.out',
      skewY: .1,
      skewX: 0,
      rotationX:180,
      stagger: 0.1,
      autoAlpha: 1,
      delay: .2
    })
    gsap.to(heading, {
      yPercent: 0,
      skewY: 0,
      skewX:0,
      rotationX:0,
      ease: 'circ.out',
      duration:1,
      transformOrigin: "0% 100%",
      autoAlpha: 1
    });

  });


  fadesTop.forEach(fade => {
    if (fade.anim) {
      fade.anim.progress(1).kill();
      fade.split.revert();
    }
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: fade,
        start: "top 100%",
        toggleActions: "restart pause resume reverse",
      }
    });

    tl.fromTo(fade, {
      opacity: 0,
      y: -50,
      duration: 1
    }, {
      opacity: 1,
      duration: 1,
      autoAlpha: 1,
      y: 0,
      delay:.3
    });
  });

  fadesBottom.forEach(fade => {
    if (fade.anim) {
      fade.anim.progress(1).kill();
      fade.split.revert();
    }
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: fade,
        start: "top 100%",
        toggleActions: "restart pause resume reverse",
      }
    });

    tl.fromTo(fade, {
      opacity: 0,
      y: 100,
    }, {
      opacity: 1,
      duration: 1,
      autoAlpha: 1,
      y: 0,
      delay: 0.5
    });
  });

  fadesLeft.forEach(fade => {
    if (fade.anim) {
      fade.anim.progress(1).kill();
      fade.split.revert();
    }
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: fade,
        start: "top 100%",
        toggleActions: "restart pause resume reverse",
      }
    });

    tl.fromTo(fade, {
      opacity: 0,
      x: 100,
    }, {
      opacity: 1,
      duration: 1,
      autoAlpha: 1,
      x: 0,
      delay: 0.5
    });
  });


  fadesRight.forEach(fade => {
    if (fade.anim) {
      fade.anim.progress(1).kill();
      fade.split.revert();
    }
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: fade,
        start: "top 100%",
        toggleActions: "restart pause resume reverse",
      }
    });

    tl.fromTo(fade, {
      opacity: 0,
      x: -100,
    }, {
      opacity: 1,
      duration: 1,
      autoAlpha: 1,
      x: 0,
      delay: 0.5
    });
  });

  fadeIn.forEach(fadein => {
    if (fadein.anim) {
      fadein.anim.progress(1).kill();
      fadein.split.revert();
    }
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: fadein,
        start: "top 100%",
        toggleActions: "restart pause resume reverse",
      }
    });

    tl.fromTo(fadein, {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 1,
      autoAlpha: 1,
      delay: 0.5
    });
  });

}

export function initGSAP() {

  headings = document.querySelectorAll(".skew");
  fadesTop = document.querySelectorAll(".fadein-top, .wp-block-column");
  fadesBottom = document.querySelectorAll(".fadein-bottom");
  fadesLeft = document.querySelectorAll(".fadein-left");
  fadesRight = document.querySelectorAll(".fadein-right");
  fadeIn = document.querySelectorAll(".fadein");
  // const separators = document.querySelectorAll("hr");

  ScrollTrigger.addEventListener("scroll", setupSplits); 
  setupSplits();

}

jQuery(window).load(initGSAP);

